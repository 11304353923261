/* Form */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);


::placeholder {
  color: var(--RS-dark-grey-color);
  opacity: 1; 
}

::-ms-input-placeholder { 
  color: var(--RS-dark-grey-color);
}

.RS-form{
  margin: var(--RS-space-24) 0;
  user-select: none;
  max-width: var(--RS-col-4);
}

.RS-form--no-margin{
  margin: 0;
}

.RS-form--tile{
  padding: unset;
  width: 100%;
  max-width: var(--RS-col-3);
  margin: 0;
}

.RS-form--modal{
  padding: unset;
  width: 100%;
  max-width: 100%;
  margin: 0;
}


.RS-form-inner-wrapper{
  display: flex;
  padding: var(--RS-space-16) 0;
  align-items: flex-start;
}

.RS-form-inner-wrapper--col{
  flex-direction: column;
  gap: var(--RS-space-24);

  @media (--lg-viewport) {
    flex-direction: row;
    gap: var(--RS-space-32);
  }
}

.RS-form-inner-wrapper--row{
  flex-direction: column;
  gap: var(--RS-space-16);
}

.RS-field-group{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.RS-label{
  font-size: var(--RS-font-size-h2);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-label--disclaimer{
  font-weight: var(--RS-font-weight-variable-bold);
  display: flex;
  color: rgba(16,15,15,.6);
  font-size: var(--RS-font-size-disclaimer);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-label + .RS-input[type=text],
.RS-label + .RS-input[type=email]{
  margin-top: var(--RS-space-8);
}

.RS-input[type=text],
.RS-input[type=email]{
  padding: var(--RS-space-12) var(--RS-space-16);
  border: unset;
  border: var(--RS-space-2) solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-12);
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-light);
}


.RS-input[type=text]:focus,
.RS-input[type=email]:focus{
  outline: unset;
  border-color: var(--RS-dark-grey-color);
}

.RS-input[type="radio"],
.RS-input[type="checkbox"] {
  display: none;
}

.RS-input[type="radio"] + label,
.RS-input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
}

.RS-input[type="radio"] + label:before,
.RS-input[type="checkbox"] + label:before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: calc(var(--RS-space-32) - var(--RS-space-2));
  height: calc(var(--RS-space-32) - var(--RS-space-2));
  border: var(--RS-space-2) solid var(--RS-light-grey-color);
  margin-right: var(--RS-space-8);
  flex-shrink: 0;
  background-color: #fff;
  @media (--lg-viewport) {
    margin-right: var(--RS-space-6);
  }
}

.RS-input[type="radio"] + label:before{
  border-radius: 50%;
}


.RS-input--radio-checkbox[type="radio"] + label:before,
.RS-input[type="checkbox"] + label:before{
  border-radius: var(--RS-space-6);
}



.RS-input[type="radio"] + label:after,
.RS-input[type="checkbox"] + label:after {
  content: ""; 
  position: absolute;
  top: calc(var(--RS-space-12) + var(--RS-space-4));
  left: calc(var(--RS-space-4) + var(--RS-space-2) + var(--RS-space-1));
  width: calc(var(--RS-space-24) - var(--RS-space-8));
  height: calc(var(--RS-space-24) - var(--RS-space-8));
  color: var(--RS-black-color);
  font-size: var(--RS-space-24);
  opacity: 0; 
  background-color: var(--RS-brand-color);
}

.RS-input[type="radio"] + label:after {
  border-radius: 50%;
  clip-path: unset;
}

.RS-input--radio-checkbox[type="radio"] + label:after,
.RS-input[type="checkbox"] + label:after{
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}


.RS-input[type="radio"]:checked + label:after,
.RS-input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.RS-input--narrow{
  max-width: unset;

  @media (--sm-viewport) {
    max-width: calc(var(--RS-space-256) + var(--RS-space-32));
  }
}



.RS-radio-group,
.RS-checkbox-group{
  min-height: var(--RS-space-48);
}

.RS-radio-group .RS-label,
.RS-checkbox-group .RS-label{
  display: flex;
  align-items: center;
  min-height: var(--RS-space-48);
  -webkit-tap-highlight-color: transparent;
}


@keyframes pulseError {
  50% {
    opacity: 0;
  }
} 
._error-inner._below{
  bottom: 0;
}


.RS-error{
  display: none;

  font-size: var(--RS-font-size-disclaimer);
  line-height: var(--RS-line-height-xlg);
  font-weight: var(--RS-font-weight-variable-bold);
  margin: var(--RS-space-2)0;
}

.RS-error:before{
  content: "!";
  margin-right: var(--RS-space-4);
  color: var(--RS-brand-color);
  animation: 1s linear infinite pulseError;
}

.RS-error--is-visible{
  display: block;
}


.RS-form-inner-wrapper p{
  font-size: var(--RS-font-size-p);
  display: flex;
  align-items: center;

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }
}


.RS-form .RS-button{
  margin-top: var(--RS-space-12);
}

.RS-form .RS-button:hover,
.RS-form .RS-button:focus{
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
  background-color: var(--RS-primary-button-color);
}

.RS-form .RS-button__arrow{
  position: absolute;
  right: var(--RS-space-48);
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  vertical-align: -0.125em;
  margin: 0 0 0 var(--RS-space-16);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
  top: 30%;

  @media (--lg-viewport) {
    top: 27.5%;
    right: var(--RS-space-24);
  }
}

.RS-submit--is-hidden{
  opacity: .5;
  pointer-events: none;
}

.RS-submit-text--is-hidden{
  visibility: hidden;
  opacity: 0;
}

.RS-button__loader{
  position: absolute;
  width: calc(var(--RS-space-24) - var(--RS-space-2));
  height: calc(var(--RS-space-24) - var(--RS-space-2));
  border-radius: 50%;
  border: 3px solid var(--RS-white-color);
  border-top-color: transparent;
  left: calc(50% - (var(--RS-space-24) - var(--RS-space-2)) / 2);
  animation: loaderSpin 1s linear infinite;
  display: none;
  animation-play-state: paused;
}

@keyframes loaderSpin {
  100%{ 
    transform: rotate(360deg); 
  }
}

.RS-button__loader--is-running{
  display: block;
  animation-play-state: running;
}

.RS-button__arrow.RS-button__arrow--is-hidden{
  display: none;
}

.RS-form ul{
  padding-left: 0;
}

.RS-label--title,
.RS-form p{
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-regular);
  font-family: "PlayfairDisplay-Variable", system-ui, serif;
  margin-bottom: var(--RS-space-12);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h3-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.asterisk{
  display: inline-block;
}