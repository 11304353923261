::placeholder {
  color: var(--RS-dark-grey-color);
  opacity: 1;
}

::-moz-placeholder {
  color: var(--RS-dark-grey-color);
}

.RS-form {
  margin: var(--RS-space-24) 0;
  -webkit-user-select: none;
  user-select: none;
  max-width: var(--RS-col-4);
}

.RS-form--no-margin {
  margin: 0;
}

.RS-form--tile {
  padding: unset;
  width: 100%;
  max-width: var(--RS-col-3);
  margin: 0;
}

.RS-form--modal {
  padding: unset;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.RS-form-inner-wrapper {
  padding: var(--RS-space-16) 0;
  align-items: flex-start;
  display: flex;
}

.RS-form-inner-wrapper--col {
  gap: var(--RS-space-24);
  flex-direction: column;
}

@media (min-width: 1024px) {
  .RS-form-inner-wrapper--col {
    gap: var(--RS-space-32);
    flex-direction: row;
  }
}

.RS-form-inner-wrapper--row {
  gap: var(--RS-space-16);
  flex-direction: column;
}

.RS-field-group {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.RS-label {
  font-size: var(--RS-font-size-h2);
}

@media (min-width: 1024px) {
  .RS-label {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-label--disclaimer {
  font-weight: var(--RS-font-weight-variable-bold);
  color: #100f0f99;
  font-size: var(--RS-font-size-disclaimer);
  display: flex;
}

@media (min-width: 1024px) {
  .RS-label--disclaimer {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-label + .RS-input[type="text"], .RS-label + .RS-input[type="email"] {
  margin-top: var(--RS-space-8);
}

.RS-input[type="text"], .RS-input[type="email"] {
  padding: var(--RS-space-12) var(--RS-space-16);
  border: unset;
  border: var(--RS-space-2) solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-12);
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-light);
}

.RS-input[type="text"]:focus, .RS-input[type="email"]:focus {
  outline: unset;
  border-color: var(--RS-dark-grey-color);
}

.RS-input[type="radio"], .RS-input[type="checkbox"] {
  display: none;
}

.RS-input[type="radio"] + label, .RS-input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
}

.RS-input[type="radio"] + label:before, .RS-input[type="checkbox"] + label:before {
  content: "";
  width: calc(var(--RS-space-32)  - var(--RS-space-2));
  height: calc(var(--RS-space-32)  - var(--RS-space-2));
  border: var(--RS-space-2) solid var(--RS-light-grey-color);
  margin-right: var(--RS-space-8);
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  :is(.RS-input[type="radio"] + label:before, .RS-input[type="checkbox"] + label:before) {
    margin-right: var(--RS-space-6);
  }
}

.RS-input[type="radio"] + label:before {
  border-radius: 50%;
}

.RS-input--radio-checkbox[type="radio"] + label:before, .RS-input[type="checkbox"] + label:before {
  border-radius: var(--RS-space-6);
}

.RS-input[type="radio"] + label:after, .RS-input[type="checkbox"] + label:after {
  content: "";
  top: calc(var(--RS-space-12)  + var(--RS-space-4));
  left: calc(var(--RS-space-4)  + var(--RS-space-2)  + var(--RS-space-1));
  width: calc(var(--RS-space-24)  - var(--RS-space-8));
  height: calc(var(--RS-space-24)  - var(--RS-space-8));
  color: var(--RS-black-color);
  font-size: var(--RS-space-24);
  opacity: 0;
  background-color: var(--RS-brand-color);
  position: absolute;
}

.RS-input[type="radio"] + label:after {
  clip-path: unset;
  border-radius: 50%;
}

.RS-input--radio-checkbox[type="radio"] + label:after, .RS-input[type="checkbox"] + label:after {
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.RS-input[type="radio"]:checked + label:after, .RS-input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.RS-input--narrow {
  max-width: unset;
}

@media (min-width: 480px) {
  .RS-input--narrow {
    max-width: calc(var(--RS-space-256)  + var(--RS-space-32));
  }
}

.RS-radio-group, .RS-checkbox-group {
  min-height: var(--RS-space-48);
}

.RS-radio-group .RS-label, .RS-checkbox-group .RS-label {
  min-height: var(--RS-space-48);
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  display: flex;
}

@keyframes pulseError {
  50% {
    opacity: 0;
  }
}

._error-inner._below {
  bottom: 0;
}

.RS-error {
  font-size: var(--RS-font-size-disclaimer);
  line-height: var(--RS-line-height-xlg);
  font-weight: var(--RS-font-weight-variable-bold);
  margin: var(--RS-space-2) 0;
  display: none;
}

.RS-error:before {
  content: "!";
  margin-right: var(--RS-space-4);
  color: var(--RS-brand-color);
  animation: 1s linear infinite pulseError;
}

.RS-error--is-visible {
  display: block;
}

.RS-form-inner-wrapper p {
  font-size: var(--RS-font-size-p);
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .RS-form-inner-wrapper p {
    font-size: var(--RS-font-size-p-desktop);
  }
}

.RS-form .RS-button {
  margin-top: var(--RS-space-12);
}

.RS-form .RS-button:hover, .RS-form .RS-button:focus {
  box-shadow: inset 0 0 0 0 var(--RS-primary-button-color);
  background-color: var(--RS-primary-button-color);
}

.RS-form .RS-button__arrow {
  right: var(--RS-space-48);
  height: 1em;
  vertical-align: -.125em;
  margin: 0 0 0 var(--RS-space-16);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 30%;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1024px) {
  .RS-form .RS-button__arrow {
    top: 27.5%;
    right: var(--RS-space-24);
  }
}

.RS-submit--is-hidden {
  opacity: .5;
  pointer-events: none;
}

.RS-submit-text--is-hidden {
  visibility: hidden;
  opacity: 0;
}

.RS-button__loader {
  width: calc(var(--RS-space-24)  - var(--RS-space-2));
  height: calc(var(--RS-space-24)  - var(--RS-space-2));
  border: 3px solid var(--RS-white-color);
  left: calc(50% - (var(--RS-space-24)  - var(--RS-space-2)) / 2);
  border-top-color: #0000;
  border-radius: 50%;
  animation: 1s linear infinite paused loaderSpin;
  display: none;
  position: absolute;
}

@keyframes loaderSpin {
  100% {
    transform: rotate(360deg);
  }
}

.RS-button__loader--is-running {
  animation-play-state: running;
  display: block;
}

.RS-button__arrow.RS-button__arrow--is-hidden {
  display: none;
}

.RS-form ul {
  padding-left: 0;
}

.RS-label--title, .RS-form p {
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-regular);
  margin-bottom: var(--RS-space-12);
  font-family: PlayfairDisplay-Variable, system-ui, serif;
}

@media (min-width: 1024px) {
  :is(.RS-label--title, .RS-form p) {
    font-size: var(--RS-font-size-h3-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.asterisk {
  display: inline-block;
}

.RS-faq {
  width: 100%;
  padding: unset;
  margin-top: var(--RS-space-24);
  flex-direction: column;
  display: flex;
}

.RS-faq__single {
  gap: var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  display: flex;
  position: relative;
}

.RS-faq__single:after {
  content: "";
  width: 100%;
  height: var(--RS-space-1);
  background-color: var(--RS-light-grey-color);
  border-radius: var(--RS-space-2);
  bottom: calc(0px + var(--RS-space-1-n));
  position: absolute;
}

.RS-faq__single-question {
  cursor: pointer;
  padding: var(--RS-space-24) var(--RS-space-8);
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 860px) {
  .RS-faq__single-question {
    padding: var(--RS-space-32) var(--RS-space-24);
  }
}

.RS-faq__single-answer {
  gap: var(--RS-space-16);
  padding: 0 var(--RS-space-8) var(--RS-space-32) var(--RS-space-8);
  flex-direction: column;
  display: none;
}

@media (min-width: 860px) {
  .RS-faq__single-answer {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-faq__single-answer--expanded {
  display: flex;
}

.RS-faq__single-answer p {
  line-height: var(--RS-line-height-xlg);
  font-size: var(--RS-font-size-p-desktop);
}

.RS-faq-icon {
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  margin-right: var(--RS-space-8);
  transform: translateY(var(--RS-space-2-n));
  min-width: calc(var(--RS-space-12)  + var(--RS-space-4));
}

@media (min-width: 860px) {
  .RS-faq-icon {
    margin-right: var(--RS-space-16);
    min-width: calc(var(--RS-space-12)  + var(--RS-space-8));
  }
}

.RS-faq-icon--expanded {
  transform: translateY(var(--RS-space-2-n)) rotateZ(180deg);
}

.RS-video-wrapper {
  max-width: var(--RS-col-4);
  margin: var(--RS-space-16) auto 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
  border-radius: var(--RS-space-24);
  position: relative;
}

.RS-video-wrapper--launched {
  aspect-ratio: unset;
}

.RS-video {
  aspect-ratio: 16 / 9;
}

.RS-video__overlay {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: var(--RS-space-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-video__cover {
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  border-radius: var(--RS-space-16);
  position: absolute;
  overflow: visible;
}

.RS-video__play-button {
  width: calc(var(--RS-space-64)  + var(--RS-space-8));
  height: calc(var(--RS-space-64)  + var(--RS-space-8));
  cursor: pointer;
  position: relative;
}

.RS-video__play-button:before, .RS-video__play-button:after {
  content: "";
  width: 100%;
  height: 100%;
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
  position: absolute;
}

.RS-video__play-button:before {
  width: 107.5%;
  background: var(--RS-black-color);
  opacity: .34;
  transform-origin: center;
  border-radius: 50%;
  left: -3.75%;
}

.RS-video__play-button:after {
  width: 40%;
  height: 40%;
  transform-origin: center;
  background-image: url("play4.8ac02a20.svg");
  background-position: center;
  background-repeat: no-repeat;
  top: 30%;
  left: 35%;
}

.RS-video__overlay:hover .RS-video__play-button:before {
  transform: scale(.95);
}

.RS-video__overlay:hover .RS-video__play-button:after {
  transform: scaleX(100%)scaleY(95%)translateX(-2.5%);
}

.RS-video__label {
  color: var(--RS-white-color);
  z-index: 1;
  font-size: var(--RS-font-size-disclaimer);
  transform: translateY(var(--RS-space-8));
}

@media (min-width: 1024px) {
  .RS-video__label {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-video__path {
  fill: var(--RS-light-grey-color);
}

.RS-video__wrapper-inner {
  border-radius: var(--RS-space-16);
  width: 100%;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  border-radius: var(--RS-space-24);
  overflow: hidden;
}

.RS-video__wrapper-inner--hidden, .RS-video__overlay--hidden {
  opacity: 0;
  pointer-events: none;
}

.tolstoy-widget {
  opacity: 0;
  animation: animTolstoyWidget calc(var(--RS-anim-duration-xlg) * 2) var(--RS-default-transition) 1 forwards;
  transform: translate3d(0, 0, 0);
}

@keyframes animTolstoyWidget {
  0%, 50% {
    transform: translate3d(var(--RS-space-32), 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(var(--RS-space-8), 0, 0);
    opacity: 1;
  }
}



/*# sourceMappingURL=index.e41bb2a8.css.map */
