.tolstoy-widget{
  transform: translate3d(0, 0, 0);
  opacity: 0;
  animation: animTolstoyWidget calc(var(--RS-anim-duration-xlg) * 2) var(--RS-default-transition) 1 forwards;
}

@keyframes animTolstoyWidget {
  0%,50%{
    /* transform: translateX(calc(100% + 35px)); */
    transform: translate3d(var(--RS-space-32), 0, 0);
    opacity: 0;
  }
  100%{
    transform: translate3d(var(--RS-space-8), 0, 0);
    opacity: 1;
  }
}
