/* FAQ */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-faq{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: unset;
  margin-top: var(--RS-space-24);
}

.RS-faq__single{
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-16);
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.RS-faq__single:after{
  content: '';
  width: 100%;
  height: var(--RS-space-1);
  /* background-color:  var(--RS-light-grey-color); */
  /* background-color:  var(--RS-accent-color-secondary); */
  background-color:  var(--RS-light-grey-color);
  border-radius: var(--RS-space-2);
  position: absolute;
  bottom: calc(0px+ var(--RS-space-1-n));
}

.RS-faq__single-question{
  cursor: pointer;
  padding: var(--RS-space-24) var(--RS-space-8);
  user-select: none;

  @media (--md-viewport) {
      padding: var(--RS-space-32) var(--RS-space-24);
  }
}

.RS-faq__single-answer{
  display: none;
  flex-direction: column;
  gap: var(--RS-space-16);
  padding: 0 var(--RS-space-8) var(--RS-space-32) var(--RS-space-8);

  @media (--md-viewport){
      padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-faq__single-answer--expanded{
  display: flex;
}

.RS-faq__single-answer p{
  line-height: var(--RS-line-height-xlg);
  font-size: var(--RS-font-size-p-desktop);
}

.RS-faq-icon{
  /* transform: rotateZ(180deg); */
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  margin-right: var(--RS-space-8);
  transform: translateY(var(--RS-space-2-n));
  min-width: calc(var(--RS-space-12) + var(--RS-space-4));

  @media (--md-viewport){
      margin-right: var(--RS-space-16);
      min-width: calc(var(--RS-space-12) + var(--RS-space-8));
  }
}

.RS-faq-icon--expanded{
  transform: translateY(var(--RS-space-2-n)) rotateZ(180deg);
}