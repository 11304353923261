/* Footer */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-video-wrapper{
  /* max-width: var(--RS-col-6);
  height: auto; */
  max-width: var(--RS-col-4);
  margin: var(--RS-space-16) auto 0 auto;
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
  border-radius: var(--RS-space-24);

  @media (--md-viewport) {
    /* width: 130%; */
  }
}

.RS-video-wrapper--launched{
  aspect-ratio: unset;
}

.RS-video{
  aspect-ratio: 16/9;
}

.RS-video__overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  border-radius: var(--RS-space-24);
}

.RS-video__cover{
  overflow: visible;
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  /* aspect-ratio: 1.68/1; */
  position: absolute;
  
  border-radius: var(--RS-space-16);
}

.RS-video__play-button{
  position: relative;
  width: calc(var(--RS-space-64) + var(--RS-space-8));
  height: calc(var(--RS-space-64) + var(--RS-space-8));
  cursor: pointer;
}

.RS-video__play-button:before,
.RS-video__play-button:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform var(--RS-anim-duration-sm) var(--RS-default-transition);
}

.RS-video__play-button:before{
  width: 107.5%;
  left: -3.75%;
  border-radius: 50%;
  /* background: var(--RS-brand-color); */
  background: var(--RS-black-color);
  opacity: .34;
  transform-origin: center;
}

.RS-video__play-button:after{
  background-image: url('../../images/icons/play4.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 40%;
  height: 40%;
  top: 30%;
  left: 35%;
  transform-origin: center;
}

.RS-video__overlay:hover .RS-video__play-button:before{
/* .RS-video__play-button:hover:before{ */
  /* transform: scaleX(102.5%); */
  transform: scale(.95);
  /* filter: brightness(1.1); */
  /* opacity: .55; */
}

.RS-video__overlay:hover .RS-video__play-button:after{
/* .RS-video__play-button:hover:after{ */
  transform: scaleX(100%) scaleY(95%) translateX(-2.5%);
}

.RS-video__label{
  /* color: var(--RS-black-color); */
  color: var(--RS-white-color);
  z-index: 1;
  font-size: var(--RS-font-size-disclaimer);
  transform: translateY(var(--RS-space-8));
  /* opacity: .55; */
  /* font-weight: var(--RS-font-weight-variable-bold); */
  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-video__path{
  fill: var(--RS-light-grey-color);
}

.RS-video__wrapper-inner{
  border-radius: var(--RS-space-16);
  overflow: hidden;
  width: 100%;
  /* margin-top: calc(var(--RS-space-32-n) - var(--RS-space-4-n)); */
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  border-radius: var(--RS-space-24);

  @media (--md-viewport) {
    /* width: 130%;
    margin-left: -15%; */
  }
}

.RS-video__wrapper-inner--hidden,
.RS-video__overlay--hidden{
  opacity: 0;
  pointer-events: none;
}

.RS-video__wrapper-inner--hidden{
  /* transform: scale(.995); */
}